.indeterminate-progress-bar {
    /* Rounded border */
    border-radius: 9999px;

    position: relative;
    overflow: hidden;
}

.indeterminate-progress-bar__progress {
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    width: 50%;

    /* Move the bar infinitely */
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
    animation-timing-function: linear;
}


@keyframes indeterminate-progress-bar {
    from {
        left: -50%;
    }
    to {
        left: 100%;
    }
}